<template>
  <div class="home">
    <ColorSVG />
  </div>
</template>

<script>
// @ is an alias to /src
import ColorSVG from '@/components/ColorSVG.vue'

export default {
  name: 'Home',
  components: {
    ColorSVG
  }
}
</script>
