<template>
  <div>
    <ul class="palettes">
      <li v-for="(color, index) in colors" :style="'backgroundColor:' + color" :class="{'active' : selectedIndex === index}" @click="setSelected(color, index)"></li>
    </ul>
    <div id="svgBox" v-html="file" class="border border-solid border-gray-200" ></div>
    <button @click="exportImage" class="bg-red-400 text-white py-3 px-6 text-sm uppercase tracking-wider" >export as png</button>
  </div>
</template>

<script>
import $axios from 'axios'
import * as d3 from "d3";
import d3ToPng from 'd3-svg-to-png'

export default {
  data(){
    return {
      file: null,
      colors: [
        '#cb997e',
        '#eddcd2',
        '#fff1e6',
        '#f0efeb',
        '#ddbea9',
        '#a5a58d',
        '#b7b7a4',
        '#333333',
      ],
      colorSelected: null,
      selectedIndex: null
    }
  },
  methods: {
    init(){
      const _this = this

      $axios.get('/images/sanitizer.svg')
        .then(res => {
          console.log(res.data)

          this.file = res.data
        })
        .then( res => {
          // const items = d3.selectAll("path");
          // items.style("fill", "orange");

          d3.selectAll("path, rect, circle").on("click", function(event) {
            event.stopPropagation()

            _this.setColor(event.target)
          });
        })
    },
    exportImage(){
      d3ToPng('svg', 'name', {
        download: true,
      })
      .then(fileData => {
        // console.log(fileData)
      });
    },
    setSelected(color, index){
      this.colorSelected = color
      this.selectedIndex = index
    },
    setColor(el){
      const color = this.colorSelected
      
      if(color !== null){
        d3.select(el).style("fill", color);
      }

      // console.log(this.colorSelected)
    }
  },
  computed: {
    svgFile(){
      return this.file
    }
  },
  mounted(){
    this.init()
  }
}
</script>

<style lang="scss">
#svgBox{
  max-width: 600px;
  @apply mx-auto mb-5 w-full;
}

.palettes{
  @apply flex flex-row flex-wrap w-full mx-auto justify-center mb-6;
  li{
    @apply w-12 h-12 inline-block rounded-full mx-1;
    &.active{
      @apply border-2 border-solid border-yellow-300 shadow-md;
    }
  }
}
</style>